@import "../../styles/global";

.container {
  border: 1px solid white;
}

.wrapper {
  margin-bottom: 4rem;

  @media (max-width: $md) {
    margin-bottom: 0;
  }
}

.padding {
  padding-top: 1rem;

  @media (max-width: $md) {
    padding-bottom: 3rem;
  }
}

.rotate {
  transform: translateX(-50%) rotate(-8deg);
}

.img {
  width: 100%;
  transform: rotate(-5deg);
}

.subPartSubtitle {
  font-family: $fontTaviraj;
  font-size: 1.3125rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5625rem;
  text-align: right;
}

.subPartText {
  font-family: $fontEpilogue;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 1.875rem;
}

.data {
  display: flex;
  flex-direction: column;
  margin-top: 1.875rem;

  &__purple {
    font-family: $fontEpilogue;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 0.875rem;
    text-align: left;
    color: #C7BAFF;
    margin-bottom: 0.75rem;
    margin-top: 1.75rem;
  }

  &__value {
    font-family: $fontEpilogue;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: left;
    padding-bottom: 1.875rem;
    border-bottom: 1px solid white;
  }

  &__network {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      text-transform: uppercase;
      font-family: $fontEpilogue;
    }
  }
}

$bubbleTransitionTime: 1s;

.fiveWrapper {
  position: relative;
  margin: 4rem 0;
  display: inherit;
  border: 1px solid white;
  border-radius: 100vmax;
  padding: 0.5rem;
  overflow: hidden;

  &:before, &:after {
    content: '';
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    background-color: white;
    transition: $bubbleTransitionTime width ease-in-out;
    border-radius: 100vmax;
    z-index: 0;
  }

  &:before {
    left: 0;
  }


  &:hover {

    &:before {
      width: 100%;
      transition: $bubbleTransitionTime width ease-in-out;
      animation: bubble $bubbleTransitionTime ease-in-out;
    }
  }

  &__text {
    font-family: $fontTaviraj;
    font-size: 1.75em;
    padding: 1rem;
    font-style: italic;
    font-weight: 500;
    line-height: 3rem;
    text-align: left;
    display: flex;
    align-items: center;
    mix-blend-mode: difference;
    overflow-x: hidden;

    span {
      display: block;
      align-items: center;
      mix-blend-mode: difference;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &__button {
    width: 100%;
    transform: rotate(-6deg);
  }
}
