@import "../../../styles/global";

.container {
  display: flex;
  flex-direction: column;
}

.text, .subText {
  margin: 0;
  font-size: 4.75rem;
  line-height: 5.5rem;
  display: flex;

  @media (max-width: $sm) {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}

.text {
  font-family: $fontEpilogue;
  font-weight: 500;
}

.subText {
  font-family: $fontTaviraj;
  font-style: italic;
  font-weight: 400;
}

.since {
  display: flex;
  font-family: $fontTaviraj;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: right;
  font-style: italic;
  margin-left: auto;
  justify-content: end;
}
