@import "media-queries";
$nb-col: 13;

.grid {
  display: grid;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @for $i from 1 through $nb-col {

    &__#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }

    &__spacing {

      &__#{$i} {
        grid-column-gap: calc(#{$i} * 10px);
      }
    }
  }
}

@for $i from 1 through $nb-col {

  @for $y from 1 through $nb-col {

    .col_xl_#{$i}_#{$y} {
      grid-column: #{$i} / #{$y};
    }

    @include xl {

      .col_xl_#{$i}_#{$y} {
        grid-column: #{$i} / #{$y} !important;
      }
    }

    @include lg(true, true) {

      .col_lg_#{$i}_#{$y} {
        grid-column: #{$i} / #{$y} !important;
      }
    }

    @include md(true, true) {

      .col_md_#{$i}_#{$y} {
        grid-column: #{$i} / #{$y} !important;
      }
    }

    @include sm {

      .col_sm_#{$i}_#{$y} {
        grid-column: #{$i} / #{$y} !important;
      }
    }
  }
}
