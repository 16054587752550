@import "../../styles/global";

.menu {
  display: flex;
  flex-direction: row;
  height: 72px;
  margin-bottom: 100px;
  max-width: $maxWidth;

  @media (max-width: $sm) {
    margin-bottom: 50px;
  }

  &__title {
    color: white;
    margin: auto auto auto 0;
    font-family: $fontEpilogue;
  }

  &__burger {
    display: none;
    width: 40px;
    height: 25px;
    position: fixed;
    right: 5vw;
    top: 20px;
    z-index: 9;
    border: none;
    background: none;
    border-bottom: 1px solid white;
    transition: border-bottom 1s;

    &:after, &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      background: white;
      transition: transform $transition-time ease-in-out;
      transform-origin: center;
    }

    &:after {
      top: 50%;
    }

    &__active {
      border-bottom: 0;

      &:after {
        transform: rotate(45deg);
        top: 50%;
      }

      &:before {
        transform: rotate(-45deg);
        top: 50%;
      }
    }

    @media (max-width: $sm) {
      display: flex;
    }
  }

  &__items {
    margin: auto 0 auto auto;
    z-index: 8;

    @media (max-width: $sm) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: black;

      &__active {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__item, &__logo {
      margin: 0 2rem;
      opacity: 1;
      transition: opacity $transition-time ease-in-out;
      z-index: 9;

      &__active {
        margin: 1rem 2rem;
        transition: $transition-time transform ease-in-out;
        animation: fadeDown $transition-time ease-in-out;
      }
    }

    &__item {
      font-size: 1rem;
      font-family: $fontEpilogue;
      color: white;
      font-style: normal;
      text-decoration: none;
      opacity: 0.5;
      transition: opacity $transition-time ease-in-out;

      @media (min-width: $sm) {
        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      @media (max-width: $sm) {
        font-size: 3.5rem;
        opacity: 1;
        padding: 1rem 0;
      }

      &:hover {
        opacity: 1;
        transition: opacity $transition-time ease-in-out;
      }
    }

    &__logo {
      margin-left: 0;
      opacity: 1;
      border-bottom: none;
    }
  }
}
