@import "../../styles/global";

.img {
  width: 100%;

  &__withEffect {
    transition: clip-path 1s ease;
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  &__visible {
    transition: clip-path 1s ease;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
