@import "../../styles/global";

.expertise {
  width: 100%;

  &__title, &__subtitle {
    display: block;
  }

  &__title {
    font-family: "Epilogue", sans-serif;
    font-size: 3rem;
    font-weight: 500;
    line-height: 57.6px;
    text-align: left;
  }

  &__subtitle {
    font-family: "Taviraj", sans-serif;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  &__list {
    padding: 0;

    &__item {
      font-family: "Epilogue", sans-serif;
      font-size: 1rem;
      font-weight: 500;
      line-height: 51.2px;
      text-align: left;
      list-style: none;
      padding: 0;
      border-bottom: 1px solid white;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
