@import "../../styles/global";

.container {
  margin: 5rem 0;

  @media (max-width: $md) {
    margin: 3rem 0;
  }

  &__common {

    @media (max-width: $md) {
      margin-top: 1rem;
    }
  }
}
