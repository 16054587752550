@import "../../styles/global";

.tag {
  font-family: $fontTaviraj;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  border: 1px solid white;
  border-radius: 100vmax;
  padding: 5px 10px;
  margin: 5px 5px 5px 0;
  display: inline-block;
}

@for $i from 1 through 100 {

  .padding__#{$i} {
    padding: calc(#{$i}px / 2) #{$i}px;
  }
}
