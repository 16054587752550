@import "../../styles/global";

.white {
  background-color: #6548E0;
  color: white;
}

.black {
  background-color: white;
  color: black;
}

.fontSize {

  &__16 {
    font-size: 1rem;
  }

  &__18 {
    font-size: 1.125rem;
  }

  &__20 {
    font-size: 1.25rem;
  }
}

.container {
  margin: auto;
  display: flex;
  aspect-ratio: 1 / 1;
  width: 100%;
  border: none;
  position: relative;
  border-radius: 100%;
  opacity: 1;
  transition: $transition-time transform ease-in-out;
  transform-style: preserve-3d;
  min-width: 100px;
  min-height: 100px;

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 75%;
    font-family: $fontTaviraj;
    font-style: italic;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
  }

  &__hover {

    &:hover {
      transition: $transition-time transform ease-in-out;
      animation: scaleBounce $transition-time ease-in-out forwards;
    }
  }
}
