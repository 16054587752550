@import "../../../styles/global";

.wrapper {
  background-color: black;
  position: relative;
  z-index: 1;
  padding: 50px 0;
  margin-top: 50px;

  &:before, &:after {
    z-index: -1;
    content: '';
    position: absolute;
    background-color: black;
    height: 100%;
    width: 100%;
  }

  &:before {
    left: 100%;
    top: 0;
  }

  &:after {
    right: 100%;
    top: 0;
  }
}

.work {
  display: flex;
  margin-bottom: 50px;

  &__title {
    font-family: $fontEpilogue;
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.5625rem;
    text-align: left;
  }

  &__subtitle {
    font-family: $fontTaviraj;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-bottom: 25px;
  }

  &__item {
    font-family: $fontEpilogue;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid grey;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    transition: 0.25s ease-in-out;

    &:hover {
      opacity: 0.5;
    }

    &__active {
      border-bottom: 1px solid white;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-self: center;

    @media (max-width: $md) {
      margin: 2rem 0;
    }

    &__pic {
      margin: auto;
      border-radius: 100%;
      width: 100%;
      max-width: 100px;

      @media (max-width: $md) {
        max-width: 75px;
      }
    }
  }

  &__comment {
    display: flex;
    flex-direction: column;
    margin: 3.125rem 0;
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: height 1s ease-in-out;

    @media (max-width: $md) {
      margin: 0;
    }

    &__text {
      font-family: $fontEpilogue;
      font-size: 0.938rem;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      position: relative;
    }

    &__author, &__job {
      display: block;
      width: 100%;
    }

    &__author {
      font-family: $fontEpilogue;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      margin-top: 10px;
    }

    &__job {
      font-family: $fontTaviraj;
      font-size: 15px;
      font-style: italic;
      font-weight: 500;
      line-height: 22.5px;
      text-align: left;
    }
  }
}
