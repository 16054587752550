@import "styles/global";

html {
  scroll-behavior: smooth;
  font-size: 16px !important;

  @media (max-width: $md) {
    font-size: 14px !important;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0C0C0C;
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: inherit !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper {
  background: #0C0C0C;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  &__page {
    margin: auto;
    color: white;
    max-width: $maxWidth;
    padding: 0 4vw;

    @media (max-width: $xl) {
      max-width: 1400px;
    }

    @media (max-width: $lg) {
      max-width: 1124px;
    }

    @media (max-width: $md) {
      max-width: calc(100vw - 16px);
    }
  }
}
