@import "../../styles/global";

.container {

  &.mainText {
    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: $md) {
      margin-bottom: 50px;
    }
  }

  &__text, &__sub {
    margin: 0;
    font-size: 4.75rem;
    line-height: 5.7rem;

    @media (max-width: $sm) {
      font-size: 2.8rem;
      line-height: 3.5rem;
    }
  }

  &__text {
    font-family: $fontEpilogue;
  }

  &__sub {
    font-family: $fontTaviraj;
    font-style: italic;
  }

  &__since {
    font-family: $fontTaviraj;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    padding-left: 25%;
    font-style: italic;
  }

  &__img {
    width: 100%;
    transform: translate(25%) rotate(6.59deg);
    z-index: 0;
  }
}

.button {
  margin: auto;
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.selection {
  margin-bottom: 4rem;

  &__text {
    margin: 7.8125rem 0;

    @media (max-width: $md) {
      margin: 50px 0;
    }
  }
}

.expertise {
  @media (max-width: $md) {
    margin: 10vh 0;
  }

  &__title, &__subtitle {
    display: block;
  }

  &__title {
    font-family: $fontEpilogue;
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.6rem;
    text-align: left;
  }

  &__subtitle {
    font-family: $fontTaviraj;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }

  &__list {
    padding: 0;

    &__item {
      font-family: $fontEpilogue;
      font-size: 1rem;
      font-weight: 500;
      line-height: 3.2rem;
      text-align: left;
      list-style: none;
      padding: 0;
      border-bottom: 1px solid white;
    }
  }
}
