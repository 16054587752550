@import "../../styles/global";

.title, .titleTwo {
  margin: 0;
  line-height: 1;
}

.title {
  text-align: left;
  font-family: $fontEpilogue;
  font-size: 4.75rem;
  font-weight: 500;
  margin-top: 50px;
}

.titleTwo {
  font-family: $fontTaviraj;
  font-size: 5.375rem;
  font-style: italic;
  font-weight: 400;
}

.menu {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: $md) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__subTitle {
    font-family: $fontEpilogue;
    font-size: 1.75rem;
    font-weight: 500;
    text-align: left;
    margin: 0;

    @media (max-width: $md) {
      text-align: center;
      margin-top: 2rem;
    }
  }
}

.projects {

  &__item {

    @media (max-width: $md) {
      margin-bottom: 4rem;
    }
  }

  &__epi {
    padding-top: 50px;

    @media (max-width: $md) {
      padding-top: 0;
    }
  }

  &__bou {
    margin-top: -50px;

    @media (max-width: $md) {
      margin-top: 0;
    }
  }

  &__laby {
    margin-top: -50%;

    @media (max-width: $md) {
      margin-top: 0;
    }
  }

  &__youps {
    padding-top: 100px;

    @media (max-width: $md) {
      padding-top: 0;
    }
  }

  &__cinq {
    transform: translateY(-50%);

    @media (max-width: $md) {
      transform: translateY(0);
    }
  }
}

.wrapper {
  width: 100%
}

.round {
  background-color: black;
  position: relative;
  z-index: 1;
  padding: 100px 0;

  &:before, &:after {
    z-index: -1;
    content: '';
    position: absolute;
    background-color: black;
    height: 100%;
    width: 100%;
  }

  &:before {
    left: 100%;
    top: 0;
  }

  &:after {
    right: 100%;
    top: 0;
  }

  &__box {
    text-align: center;
    margin: 50px 0;

    &__title {
      font-family: $fontEpilogue;
      font-size: 2.25rem;
      font-weight: 500;
      line-height: 43.2px;
      text-align: center;
      margin: 0;
    }

    &__action {
      font-family: $fontTaviraj;
      font-size: 1.3125rem;
      font-style: italic;
      font-weight: 400;
      line-height: 25.2px;
      text-align: center;
    }
  }

  &__img {
    width: 100%;

    &__one {
      padding-top: 50px;
    }

    &__three {
      padding-top: 50px;
      transform: translateY(50px);

      @media (max-width: $md) {
        transform: translateY(0);
      }
    }

    &__last {
      padding-top: 50px;
    }
  }
}
