//xs, extra-small: 0px
//sm, small: 600px
//md, medium: 900px
//lg, large: 1200px
//xl, extra-large: 1536px
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;
$maxWidth: 1700px;

// Small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}

// Medium devices
@mixin md($min, $max) {
  @if $min and $max {
    @media (min-width: #{$sm}) and (max-width: #{$md}) {
      @content;
    }
  } @else if $min {
    @media (min-width: #{$sm}) {
      @content;
    }
  } @else {
    @media (max-width: #{$md}) {
      @content;
    }
  }
}

// Large devices
@mixin lg($min, $max) {
  @if $min and $max {
    @media (min-width: #{$md}) and (max-width: #{$lg}) {
      @content;
    }
  } @else if $min {
    @media (min-width: #{$md}) {
      @content;
    }
  } @else {
    @media (max-width: #{$lg}) {
      @content;
    }
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$lg}) {
    @content;
  }
}
