@import "../../styles/global";

.container {
  display: flex;
  flex-direction: column;
  font-family: $fontEpilogue;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  white-space: pre-line;
}
