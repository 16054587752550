$minSize: 24;

@mixin loop {
  @for $i from 1 through 20 {
    $box: $minSize + $i;
    $size: $box / 2;
    $r: $size / 2;

    #{$i * 5}% {
      cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="#{$box}" height="#{$box}" viewBox="0 0 #{$box} #{$box}"><circle cx="#{$size}" cy="#{$size}" r="#{$r}" fill="%23FFF" stroke="%23FFFFFF" stroke-width="0.25"/></svg>') #{$size} #{$size}, pointer;
    }
  }
}

@keyframes cursor {
  @include loop;
}

* {
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="6" fill="%23FFF" stroke="%23FFFFFF" stroke-width="0.25"/></svg>') 12 12, pointer;
}
