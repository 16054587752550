@import "../../styles/global";

.footer {
  margin-top: 5rem;
  padding-bottom: 3.125rem;

  @media (max-width: $md) {
    margin-top: 3.125rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    display: block;
    font-family: $fontEpilogue;
    font-size: 2.4375rem;
    font-weight: 500;
    line-height: 46.8px;
    text-align: left;

    @media (max-width: $sm) {
      text-align: center;
    }
  }

  &__author {
    display: flex;
    flex-direction: row;

    a {
      display: inherit;
      width: 100%;
    }

    &__title {
      font-family: $fontTaviraj;
      font-size: 1.25rem;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
      margin: auto 1.25rem auto auto;

      @media (max-width: $sm) {
        text-align: center;
      }
    }

    &__pic {
      width: auto;
      height: 100%;

      @media (max-width: $sm) {
        margin-right: auto;
      }
    }
  }

  &__five {
    font-family: $fontTaviraj;
    font-size: 1.375rem;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-top: 0;

    @media (max-width: $lg) {
      text-align: center;
      margin-top: 25px;
    }
  }

  &__email, &__textToMe {
    display: block;

    text-align: left;

    @media (max-width: $lg) {
      text-align: center;
    }
  }

  &__textToMe {
    font-family: $fontEpilogue;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 1rem;

    @media (max-width: $lg) {
      margin-top: 1rem;
    }
  }

  &__email {
    font-family: $fontEpilogue;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.125rem;

    @media (max-width: $lg) {
      margin-bottom: 1.125rem;
    }
  }

  &__illu {
    width: 50%;
    margin-left: auto;
  }

  &__img {
    width: 80%;
    transform: rotate(-6.59deg);
    z-index: 0;
  }

  &__picmore {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    margin-top: 2rem;

    @media (max-width: $md) {
      display: none;
    }
  }

  &__button {
    position: absolute;
    top: 25%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(10deg);
    width: 60%;

    @media (max-width: $md) {
      width: 50%;
    }
  }

  &__socialNetwork {

    &__title {
      display: block;
      font-family: $fontEpilogue;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 0.875rem;
      text-align: left;
      padding-bottom: 0.9375rem;
      padding-top: 3.125rem;
      width: 100%;
    }

    &__wrapper {
      margin-bottom: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__item {
        display: flex;
        font-family: $fontEpilogue;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.125rem;
        text-align: left;
        opacity: 1;
        transition: opacity $transition-time ease-in-out;
      }

      &:hover {

        .footer__socialNetwork__wrapper__item {
          opacity: 0.5;
          transition: opacity $transition-time ease-in-out;

          &:hover {
            opacity: 1;
            transition: opacity $transition-time ease-in-out;
          }
        }
      }
    }
  }

  &__copyright {
    border-top: 1px solid white;
    margin-top: auto;
    padding-top: 1.5rem;

    &__text {
      font-family: $fontEpilogue;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 0.75rem;
      text-align: left;
    }

    @media (max-width: $lg) {
      margin-top: 3.125rem;
    }
  }
}
