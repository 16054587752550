@import "../../styles/global";

.container {

  &__text {
    margin: 1rem 0;

    &:first-of-type {
      margin-top: 4rem;
    }
  }
}

.img {
  margin-top: 50px;
  margin-left: 50px;
  transform: rotate(-6.59deg);
  z-index: 0;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transform: translateX(-45%);
}

.skills {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.tools {
  width: 100%;
  margin-bottom: 100px;
}

.ellipse {
  width: 100%;

  &__link {
    transform: translateX(-50%) rotate(-7deg);
  }

  &__pic {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__translateDown {
      transform: translateY(10%);
    }

    &__flower {
      margin-top: 50%;
    }

    &__bee {
      margin-top: -25%;
    }
  }

  &__title {
    text-align: center;
    font-family: $fontEpilogue;
    font-size: 3rem;
    font-weight: 500;
  }

  &__subtitle {
    font-family: $fontTaviraj;
    font-size: 1.3125rem;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    margin-top: 0.5rem;
  }

  &__text {
    font-family: $fontEpilogue;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-top: 1.5625rem;
  }
}

.blackBar {
  background-color: black;
  position: relative;
  z-index: 1;
  padding: 6.25rem 0;
  margin-top: 5rem;

  &:before, &:after {
    z-index: -1;
    content: '';
    position: absolute;
    background-color: black;
    height: 100%;
    width: 100%;
  }

  &:before {
    left: 100%;
    top: 0;
  }

  &:after {
    right: 100%;
    top: 0;
  }

  &__title {
    font-family: $fontTaviraj;
    font-size: 1.3125rem;
    font-style: italic;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    margin-bottom: 1.5625rem;
  }

  &__text {
    font-family: $fontEpilogue;
    font-size: 4.375rem;
    font-weight: 500;
    line-height: 84px;
    text-align: left;

    @media (max-width: $sm) {
      font-size: 3.2rem;
      line-height: 3.9rem;
    }
  }
}
