@import "reset";
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Taviraj:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Unbounded:wght@200..900&display=swap');
@import "colors";
@import "animations";
@import "grid";
@import "cursor";

$transition-time: 0.25s;

$fontEpilogue: "Epilogue", sans-serif;
$fontTaviraj: "Taviraj", sans-serif;
