@import "../../styles/global";

a {
  text-decoration: none;
  color: inherit;
}

.card {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 1s ease;

  &:hover {
    transition: transform 0.1s;
  }
}

.img {
  width: 100%;
  margin-bottom: 1rem;
}

.title {
  margin-top: 1rem;
  font-family: $fontEpilogue;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-transform: uppercase;
}
