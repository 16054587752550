@import "../../../styles/global";

.projects {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__title {
    display: flex;
    font-family: $fontEpilogue;
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.5625rem;
    text-align: left;
    height: 100%;
  }

  &__subtitle {
    font-family: $fontTaviraj;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    @media (max-width: $md) {
      margin-bottom: 2rem;
    }
  }

  &__wrapper {

    @media (max-width: $md) {
      margin-bottom: 4rem;
    }
  }

  &__img {
    width: 100%;
  }

  &__tag {
    font-family: $fontTaviraj;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    border: 1px solid white;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px 5px 5px 0;
    display: inline-block;
  }

  &__project {
    font-family: $fontEpilogue;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }

  &__epi {
    margin-top: 70px;

    @include md(false, true) {
      margin-top: 0;
    }
  }

  &__bou {
    margin-top: -50%;

    @include md(false, true) {
      margin-top: 0;
    }
  }
}

.button {
  margin: 25% auto auto auto;
  display: flex;
  z-index: 1;
  width: 100%;
  transform: translateX(-25%) rotate(-8deg);

  @media (max-width: $md) {
    display: none;
  }

  @include md(false, true) {
    margin: auto auto 25% auto;
  }
}
